import { useRef } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  useShoppingCart,
  LocationProduct,
} from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import { pf, getPageLocaleUrl } from "../utilities/helpers";
import useLazyLoad from "../utilities/useLazyLoad";

interface Props {
  locationProduct: LocationProduct;
}

const languageTranslations = {
  et: {
    add_to_cart: "LISA OSTUKORVI",
  },
  en: {
    add_to_cart: "ADD TO CART",
  },
};

export default function ProductComponent({ locationProduct }: Props) {
  const { increaseCartQuantity } = useShoppingCart();
  const lang = usePageLanguage();
  const productItemRef = useRef<HTMLDivElement>(null);
  const pages = useAppSelector((state) => state.builder.pages.list);
  const loaded = useLazyLoad(productItemRef, locationProduct.firstFileUrl);
  const locale = usePageLanguage();

  const productPage = pages.find(
    (page) => page.page_type === "product" && page.language.locale === lang
  );
  var languageProductHref = productPage
    ? getPageLocaleUrl(productPage).replace(/:id$/, "")
    : "/et/toode/";
  if (languageProductHref.substring(0, 1) !== "/") {
    languageProductHref = "/" + languageProductHref;
  }

  return (
    <div className="productcol">
      <div className="web-product" ref={productItemRef}>
        <a
          href={`${languageProductHref}${locationProduct.id}`}
          className="product-img"
          style={{
            backgroundImage: loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none",

            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <img
            src={
              loaded ? loaded.map((src) => `url("${src}")`).join(", ") : "none"
            }
            alt={locationProduct?.translations?.[lang].name + " pilt."}
            style={{ width: "100%" }}
          ></img>
          <div className="product-info-overlay">
            <div
              dangerouslySetInnerHTML={{
                __html: locationProduct?.translations?.[lang].shortDescription,
              }}
            />
          </div>
        </a>
        <div className="padding-10 pb-20">
          <div className="product-name">
            <a href={`${languageProductHref}${locationProduct.id}`}>
              {locationProduct?.translations?.[lang].name}&nbsp;
            </a>
          </div>
          <div className="productPrice">
            <span
              style={
                locationProduct.adjustments &&
                locationProduct.adjustments?.length > 0
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {pf(locationProduct.price_total)}&euro;
            </span>

            {locationProduct.price_with_discounts &&
            locationProduct.adjustments?.length > 0 ? (
              <span className="discount-price-color">
                {pf(Number(locationProduct.price_with_discounts))}&euro;
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            <button
              className="btn btn-primary addProductToCart shop-controlled-border-radius"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                increaseCartQuantity(locationProduct.id);
              }}
            >
              {languageTranslations[locale].add_to_cart}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
