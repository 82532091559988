import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import { format } from "date-fns";
import etLocale from "date-fns/locale/et";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PersonIcon from "@mui/icons-material/Person";
import { pf } from "../utilities/helpers";
import usePageLanguage from "../hooks/usePageLanguage";

const languageTranslations = {
  et: {
    delivery: "TARNE",
  },
  en: {
    delivery: "DELIVERY",
  },
};

export default function CheckoutDeliveryPanelConfirmed({}) {
  const { confirmedOrder, deliveryMethods, buyer, orderDeliveryMethod } =
    useShoppingCart();
  const lang = usePageLanguage();

  var deliveryMethod: DeliveryMethod | null = null;
  if (orderDeliveryMethod) {
    deliveryMethod = deliveryMethods.find(
      (i) => i.id === orderDeliveryMethod.delivery_method_id
    )!;
  }

  var receiverEmail = "";
  var receiverFirstname = "";
  var receiverSurname = "";
  var receiverPhone = "";
  if (orderDeliveryMethod && orderDeliveryMethod.receiverEmail) {
    receiverEmail = orderDeliveryMethod.receiverEmail;
    receiverFirstname = orderDeliveryMethod.receiverFirstname;
    receiverSurname = orderDeliveryMethod.receiverSurname;
    receiverPhone = orderDeliveryMethod.receiverPhone;
  } else {
    receiverEmail = buyer.email;
    receiverFirstname = buyer.firstname;
    receiverSurname = buyer.surname;
    receiverPhone = buyer.phone;
  }

  var address = "";
  var city = "";
  var postal_code = "";
  var parcelMachineName = "";
  if (orderDeliveryMethod) {
    address = orderDeliveryMethod.address ?? "";
    city = orderDeliveryMethod.city ?? "";
    postal_code = orderDeliveryMethod.postal_code ?? "";
    parcelMachineName = orderDeliveryMethod.parcelMachineName ?? "";
  }

  return (
    <div className="panel" id="checkoutDeliveryPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">3</span>
          <span className="panel-title-text">
            {languageTranslations[lang].delivery}
          </span>
          {confirmedOrder && confirmedOrder.delivery_time != undefined && (
            <span className="pull-right badge badge-square badge-primary delivery-date-badge">
              <span style={{ textTransform: "capitalize" }}>
                {format(new Date(confirmedOrder.delivery_time), "EEEE", {
                  locale: etLocale,
                })}
              </span>{" "}
              {format(new Date(confirmedOrder.delivery_time), "dd.MM.yyyy")}
            </span>
          )}
        </div>
      </div>
      <div className="panel-body">
        <div className="row" id="deliveryMethodFilled">
          <div className="col-xs-12 margin-bottom-10">
            <button
              className="btn btn-primary-outline text-left checkoutDeliveryButton"
              style={{ width: "100%" }}
            >
              {deliveryMethod?.locale_translations[lang]?.name}
              <span className="pull-right">
                {" "}
                {pf(orderDeliveryMethod!.price)} &euro;
              </span>
            </button>
          </div>
          <div className="col-xs-12">
            {orderDeliveryMethod?.delivery_method_type !== "pickup" && (
              <h3 className="addressInfo margin-top-15 margin-bottom-0">
                <PinDropIcon className="icon margin-right-10" />
                <span>
                  <span className="receiverAddress">
                    {parcelMachineName ? parcelMachineName + " (" : ""}
                    {address}
                    {city.length ? ", " + city : ""}
                    {postal_code.length ? ", " + postal_code : ""}
                    {parcelMachineName ? ")" : ""}
                  </span>
                </span>
              </h3>
            )}
            {orderDeliveryMethod?.receiverEmail &&
              orderDeliveryMethod?.receiverEmail.length != 0 && (
                <h3 className="receiverInfo margin-top-10 margin-bottom-0 ">
                  <PersonIcon className="icon" />{" "}
                  <span className="receiverName">
                    {receiverFirstname} {receiverSurname}
                  </span>
                  ,{" "}
                  <span
                    className="receiverPhone"
                    style={{ fontSize: "16px", fontWeight: "300" }}
                  >
                    {receiverPhone}
                  </span>{" "}
                  <span className="receiverEmail" style={{ fontSize: "16px" }}>
                    {receiverEmail}
                  </span>
                </h3>
              )}
          </div>
          {orderDeliveryMethod &&
          (orderDeliveryMethod.delivery_method_description ||
            orderDeliveryMethod.parcelDescription) ? (
            <div className="col-xs-12 deliveryDescription margin-top-15">
              {orderDeliveryMethod.parcelDescription ? (
                <p>{orderDeliveryMethod.parcelDescription}</p>
              ) : (
                ""
              )}
              {orderDeliveryMethod.delivery_method_description}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
