import CartComponent from "./CartComponent";
import LogoComponent from "./LogoComponent";
import NavComponent from "./NavComponent";
import LanguageComponent from "./LanguageComponent";
import MobileNavigation from "./MobileNavigation";

import {
  HeaderComponent as HeaderComponent_,
  NavComponent as NavComponent_,
  LogoComponent as LogoComponent_,
  CartComponent as CartComponent_,
  LanguageComponent as LanguageComponent_,
  AllComponents,
} from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";

const Header = styled("header")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
export default function HeaderComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: HeaderComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const structure = useAppSelector((state) => state.builder.structure);
  const component = useAppSelector(
    (state) => state.builder.structure.components[id] as HeaderComponent_
  );
  let childComponents: AllComponents[] = [];
  childWebpageComponentRelations.map((cr) => {
    const c = structure.components[cr.child_id];
    if (c) {
      childComponents.push(c);
    }
  });

  const logoComponent = childComponents.find(
    (x) => x && x.type === "logo"
  ) as LogoComponent_;
  const navComponent = childComponents.find(
    (x) => x && x.type === "nav"
  ) as NavComponent_;
  const cartComponent = childComponents.find(
    (x) => x && x.type === "cart"
  ) as CartComponent_;

  const languageComponent = childComponents.find(
    (x) => x && x.type === "language-select"
  ) as LanguageComponent_;
  // TODO: make better

  return (
    <Header
      id={id.toString()}
      data-style={attributes.styles ?? ""}
      data-component-type="header"
      className={
        "builder-component builder-component__header " +
        (attributes.classes ?? "") +
        (editorId === id ? " builder-component--active" : "")
      }
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      <nav className="navbar navbar-expand-lg">
        <div
          className="container-fluid g-0"
          style={{
            justifyContent:
              component &&
              component.attributes &&
              component.attributes.headerJustify &&
              component.attributes.headerJustify !== undefined
                ? component.attributes.headerJustify
                : "space-between",

            gap:
              component &&
              component.attributes &&
              component.attributes.headerGapNumber &&
              component.attributes.headerGapUnit
                ? `${component.attributes.headerGapNumber}${component.attributes.headerGapUnit}`
                : "0px",
          }}
        >
          {/* @ts-expect-error Server Component */}
          {logoComponent && attributes.components.logo.type !== "none" && (
            //@ts-expect-error Server Component
            <LogoComponent {...logoComponent} />
          )}
          {navComponent && attributes.components.nav.display && (
            <NavComponent
              {...navComponent}
              navAttributes={attributes.components.nav}
            />
          )}

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MobileNavigation
              {...navComponent}
              navAttributes={attributes.components.nav}
            />

            {cartComponent && attributes.components.cart.display && (
              <CartComponent {...cartComponent} />
            )}

            {attributes.components.language?.display === "block" &&
              languageComponent?.id && (
                <LanguageComponent
                  languageAttributes={attributes.components.language}
                  {...languageComponent}
                />
              )}
          </div>
        </div>
      </nav>
    </Header>
  );
}
