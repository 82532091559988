import { useState, useEffect } from "react";
import CategoriesComponent from "../Categories/CategoriesComponent";
import Pagination from "@mui/material/Pagination";

import {
  useShoppingCart,
  LocationProduct,
} from "../context/ShoppingCartContext";
import { useAppSelector } from "../../../store/hooks";
import ProductComponent from "../Products/ProductComponent";
import ProductsSortComponent from "../Products/ProductsSortComponent";
import { ProductsServicesComponent as ProductsServicesComponent_ } from "../interfaces/base";
import "./ProductsServicesComponent.css";
import ProductServicesBuyComponent from "./ProductServicesBuyComponent";
import usePageLanguage from "../hooks/usePageLanguage";

import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function ProductServicesComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: ProductsServicesComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const [searchParams, setSearchParams] = useSearchParams();

  const { locationProducts } = useShoppingCart();

  var category = Number(searchParams.get("category"));

  const [productCategoryId, setProductCategoryId] = useState<number | null>(
    category
  );
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(30);
  const [sortFilter, setSortFilter] = useState<string>("");
  const lang = usePageLanguage();
  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    // @ts-ignore
    if (searchParams?.size === 0) {
      changeCategoryId(null);
    }
  }, [searchParams]);

  switch (sortFilter) {
    case "price_asc":
      locationProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        return a.price_total - b.price_total;
      });
      break;
    case "price_desc":
      locationProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        return b.price_total - a.price_total;
      });
      break;
    case "name_asc":
      locationProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        const nameA = (a.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        const nameB = (b.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      break;
    case "name_desc":
      locationProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        const nameA = (a.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        const nameB = (b.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }

        // names must be equal
        return 0;
      });
      break;

    default:
      break;
  }

  const matchingLocationProducts = locationProducts.filter(function (
    locationProduct
  ) {
    if (productCategoryId) {
      return locationProduct.productCategories.find(function (productCategory) {
        return productCategory.id === productCategoryId;
      });
    }
    return true;
  });

  const startOffset = (page - 1) * perPage;
  const endOffset = startOffset + perPage;

  const pageLocationProducts = matchingLocationProducts.slice(
    startOffset,
    endOffset
  );
  const pageCount = Math.ceil(matchingLocationProducts.length / perPage);

  const changeCategoryId = function (categoryId: null | number) {
    if (categoryId) {
      setSearchParams({ category: categoryId.toString() });
    } else {
      setSearchParams();
    }
    setProductCategoryId(categoryId);
    setPage(1);
  };

  return (
    <Div
      id={id.toString()}
      key={id}
      data-style={attributes && attributes.styles}
      className={
        attributes &&
        attributes.classes +
          (attributes.classes != "container" &&
          attributes.classes != "container-fluid"
            ? " builder-component builder-component__products"
            : "") +
          (editorId === id ? " builder-component--active" : "")
      }
      data-component-type="section"
    >
      <div className="products_container">
        <div className="row" style={{ position: "relative" }}>
          <div className="col-lg-3 sticky">
            <CategoriesComponent
              productCategoryId={productCategoryId}
              setProductCategoryId={changeCategoryId}
            />

            <ProductServicesBuyComponent />
          </div>
          <div className="col-lg-9">
            <ProductsSortComponent
              sortFilter={sortFilter}
              setSortFilter={setSortFilter}
              perPage={perPage}
              setPerPage={setPerPage}
            />
            {/*@include('webstore.filters')*/}
            <div className="card itprCard" id="productsPanel">
              <div className="card-body">
                {pageLocationProducts.map((locationProduct: any) => {
                  return (
                    <ProductComponent
                      key={locationProduct.id}
                      locationProduct={locationProduct}
                    />
                  );
                })}
              </div>
              {pageCount > 1 && (
                <div className="card-footer">
                  <Pagination
                    className="MuiPagination-ul-center "
                    shape="rounded"
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
}
