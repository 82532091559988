import { TextField, Autocomplete } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { useShoppingCart } from "../context/ShoppingCartContext";

import usePageLanguage from "../../_default/hooks/usePageLanguage";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    buyer: "OSTJA",
    first_name: "Eesnimi",
    last_name: "Perenimi",
    phone: "Mobiil",
    email: "E-post",
    save_data: "Andmete salvestamiseks tuleb nõustuda",
    data_usega: "kasutustingimustega",
    policy: "küpsiste- ja privaatsuspoliitikaga",
    agree_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
    organization: "Äriklient",
    client: "Eraklient",
    legal_name: "Juriidiline nimi",
    reg_nr: "Registrikood",
    KMKR_nr: "KMKR nr",
    address: "Aadress",
    no_results: "Tulemusi ei leitud",
  },
  en: {
    buyer: "BUYER",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    save_data: "To save your data, you need to agree to our ",
    data_usega: "data use",
    policy: "cookies and privacy policy",
    agree_terms: "AGREE WITH TERMS AND SAVE",
    organization: "Organization",
    client: "Client",
    legal_name: "Legal name",
    reg_nr: "Registration number",
    KMKR_nr: "KMKR number",
    address: "Address",
    no_results: "No results found",
  },
};

export default function BuyerModal({ show, setShow }: Props) {
  let lang = usePageLanguage();
  const { buyer, setBuyer, getOrder } = useShoppingCart();
  const [type, setType] = useState(buyer.type || "buyer");

  const [orgHistoricalNames, setOrgHistoricalNames] = useState(
    buyer.organization_historical_names || ""
  );
  const [orgStatus, setOrgStatus] = useState(
    buyer.organization_ariregister_status || ""
  );
  const [orgUrl, setOrgUrl] = useState(
    buyer.organization_ariregister_url || ""
  );
  const [organizationOptions, setOrganizationOptions] = useState<any>([]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      type: type,
      firstname: buyer.firstname ?? "",
      surname: buyer.surname ?? "",
      phone: buyer.phone ?? "+372",
      email: buyer.email ?? "",
      organization_name: buyer.organization_name ?? "",
      organization_reg_nr: buyer.organization_reg_nr ?? "",
      organization_KMKR_number: buyer.organization_KMKR_number ?? "",
      organization_phone: buyer.organization_phone ?? "+372",
      organization_email: buyer.organization_email ?? "",
      organization_address: buyer.organization_address ?? "",
      organization_historical_names: buyer.organization_historical_names ?? "",
      organization_ariregister_status:
        buyer.organization_ariregister_status ?? "",
      organization_ariregister_url: buyer.organization_ariregister_url ?? "",
    },
  });

  const onSubmit = (data: any) => {
    const buyerData = {
      ...buyer,
      ...data,
      type: type,
      organization_historical_names: orgHistoricalNames
        ? orgHistoricalNames
        : "",
      organization_ariregister_status: orgStatus ? orgStatus : "",
      organization_ariregister_url: orgUrl ? orgUrl : "",
    };
    setBuyer(buyerData);
    setShow(false);
    getOrder(buyerData);
  };

  const handleOrganizationQuery = (query: string) => {
    const url = `https://ariregister.rik.ee/est/api/autocomplete?q=${query}&results=10`;

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (!res.data) {
          return;
        }
        const data = [...res.data].map((item: any) => {
          return {
            label: item.name,
            organization_name: item.name,
            organization_reg_nr: item.reg_code,
            organization_KMKR_number: "",
            organization_phone: "+372",
            organization_email: "",
            organization_address: `${item.legal_address}, ${item.zip_code}`,
            organization_historical_names:
              item.historical_names.length > 0
                ? `${item.historical_names
                    .map((historicalName: any) => historicalName.name)
                    .join(", ")}`
                : "",
            organization_ariregister_status: item.status ? item.status : "",
            organization_ariregister_url: item.url ? item.url : "",
          };
        });

        setOrganizationOptions(data);
      });
  };

  // Define the type for your form's field names
  type FieldName =
    | "type"
    | "firstname"
    | "surname"
    | "phone"
    | "email"
    | "organization_name"
    | "organization_reg_nr"
    | "organization_KMKR_number"
    | "organization_phone"
    | "organization_email"
    | "organization_address";

  const setFieldValue = (fieldName: FieldName, value: any) => {
    setValue(fieldName, value);
  };

  return (
    <Modal
      disableRestoreFocus
      show={show}
      onHide={() => setShow(false)}
      contentClassName="modal-600 buyerModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="badge badge-square badge-primary me-3">2</span>
          <span className="fw-bold">{languageTranslations[lang].buyer}</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              marginTop: "0px",
              marginBottom: "20px",
            }}
          >
            <div
              onClick={() => {
                setType("buyer");
              }}
              className={`
            buyerModal__btn ${
              type == "buyer" ? "buyerModal__btn--active" : ""
            }`}
            >
              {languageTranslations[lang].client}
            </div>

            <div
              onClick={() => {
                setType("organization");
              }}
              className={`buyerModal__btn ${
                type == "organization" ? "buyerModal__btn--active" : ""
              }`}
            >
              {languageTranslations[lang].organization}
            </div>
          </div>

          {type === "organization" && (
            <div className="row">
              <div className="col-md-12">
                <Controller
                  name="organization_name"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    // <TextField
                    //   className="text-field-color"
                    //   InputLabelProps={{
                    //     style: { color: "#333", fontSize: "14px" },
                    //   }}
                    //   fullWidth
                    //   // Make tab automatically active
                    //   autoFocus={true}
                    //   label={languageTranslations[lang].legal_name}
                    //   variant="standard"
                    //   value={field.value}
                    //   onChange={field.onChange}
                    //   inputRef={field.ref}
                    //   error={errors.organization_name?.type == "required"}
                    // />

                    <Autocomplete
                      className="text-field-color"
                      options={organizationOptions}
                      value={field.value}
                      freeSolo
                      // noOptionsText={languageTranslations[lang].no_results}
                      // run if selected
                      onInputChange={(event, newInputValue) => {
                        //field.onChange(newInputValue);

                        // Get the selected value from the options

                        // setFieldValue("organization_name", newInputValue);
                        const selectedValue = organizationOptions.find(
                          (option) => option.organization_name == newInputValue
                        );

                        console.log(
                          selectedValue,
                          "THIS IS THE SELECTED VALUE"
                        );

                        // Update organization_name, organization_reg_nr, organization_KMKR_number, organization_phone, organization_email, organization_address

                        if (selectedValue) {
                          setFieldValue("organization_name", newInputValue);
                          setFieldValue(
                            "organization_reg_nr",
                            selectedValue.organization_reg_nr
                          );
                          setFieldValue(
                            "organization_KMKR_number",
                            selectedValue.organization_KMKR_number
                          );
                          setFieldValue(
                            "organization_phone",
                            selectedValue.organization_phone
                          );
                          setFieldValue(
                            "organization_email",
                            selectedValue.organization_email
                          );
                          setFieldValue(
                            "organization_address",
                            selectedValue.organization_address
                          );

                          setOrgHistoricalNames(
                            selectedValue.organization_historical_names
                          );

                          setOrgStatus(
                            selectedValue.organization_ariregister_status
                          );

                          setOrgUrl(selectedValue.organization_ariregister_url);
                        }

                        console.log(event, newInputValue, "values changed");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Organization name"
                          autoFocus={true}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);

                            handleOrganizationQuery(e.target.value);
                          }}
                          label={languageTranslations[lang].legal_name}
                          error={errors.organization_name?.type == "required"}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="col-md-3">
                <Controller
                  name="organization_reg_nr"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="text-field-color"
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].reg_nr}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.organization_reg_nr?.type == "required"}
                    />
                  )}
                />
              </div>

              <div className="col-md-3">
                <Controller
                  name="organization_KMKR_number"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      className="text-field-color"
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].KMKR_nr}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={
                        errors.organization_KMKR_number?.type == "required"
                      }
                    />
                  )}
                />
              </div>

              <div className="col-md-6">
                <Controller
                  name="organization_phone"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTelInput
                      className=""
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].phone}
                      variant="standard"
                      required
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.organization_phone?.type == "required"}
                    />
                  )}
                />
              </div>

              <div className="col-md-12">
                <Controller
                  name="organization_address"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="text-field-color"
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].address}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.organization_address?.type == "required"}
                    />
                  )}
                />
              </div>

              <div className="col-md-12">
                <Controller
                  name="organization_email"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="text-field-color"
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      sx={
                        // Add margin 0 to muiInput-root
                        {
                          "& .MuiInputBase-root": {
                            marginBottom: 0,
                          },
                        }
                      }
                      FormHelperTextProps={{
                        sx: {
                          fontSize: "14px",
                        },
                      }}
                      label={languageTranslations[lang].email}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {type === "buyer" && (
            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="firstname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="text-field-color"
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      // Make tab automatically active
                      autoFocus={true}
                      label={languageTranslations[lang].first_name}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.firstname?.type == "required"}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="surname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].last_name}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.surname?.type == "required"}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="email"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      sx={
                        // Add margin 0 to muiInput-root
                        {
                          "& .MuiInputBase-root": {
                            marginBottom: 0,
                          },
                        }
                      }
                      FormHelperTextProps={{
                        sx: {
                          fontSize: "14px",
                        },
                      }}
                      label={languageTranslations[lang].email}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="phone"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTelInput
                      className=""
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].phone}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.phone?.type == "required"}
                    />
                  )}
                />
              </div>
            </div>
          )}

          <div className="mt-2 links-green">
            {languageTranslations[lang].save_data}
            <a href="/terms" target="_blank" className="color-primary">
              {languageTranslations[lang].data_usega}
            </a>
            ,{" "}
            <a href="/privacy" target="_blank" className="color-primary">
              {languageTranslations[lang].policy}
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <button
            type="submit"
            style={{
              color: "#fff",
              fontWeight: "600",
            }}
            className="btn w-100 shop-controlled-border-radius shop-controlled-color"
          >
            {languageTranslations[lang].agree_terms}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
