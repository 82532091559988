import NavItemComponent from "./NavItemComponent";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import {
  NavComponent as NavComponent_,
  NavItemComponent as NavItemComponent2,
} from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import "./Nav.css";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
export default function NavComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
  navAttributes,
}: any) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const structure = useAppSelector((state) => state.builder.structure);

  const [navBreakingPoint, setNavBreakingPoint] = useState(
    navAttributes.mediaBreakpoint ? navAttributes.mediaBreakpoint : 1000
  );

  useEffect(() => {
    setNavBreakingPoint(
      navAttributes.mediaBreakpoint ? navAttributes.mediaBreakpoint : 1000
    );
  }, [navAttributes.mediaBreakpoint]);

  return (
    <>
      <style>
        {`
      @media screen and (max-width: ${navBreakingPoint}px) {
        .navbar {
          position: unset !important;
        }

        .navbar-nav {
          display: none;
        }
        .mobile-menu-toggle {
          display: block;
        }
      
        .builder-component__nav {
          display: none;
        }
      
        .mobilemenutriggers {
          display: flex !important;
        }
      }
      }
    `}
      </style>

      <Div
        id={id.toString()}
        data-component-type="nav"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={
          (attributes.classes ?? "") +
          " builder-component builder-component__nav" +
          (editorId === id ? " builder-component--active" : " collapsed   ")
        }
        data-style={{ flexGrow: 0, ...attributes.styles }}
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations.filter(
            (componentRelation) => {
              const childComponent =
                structure.components[componentRelation.child_id];
              if (childComponent && childComponent.type == "editor") {
                return true;
              }
              return false;
            }
          )}
        />

        <ul className="navbar-nav mb">
          {childWebpageComponentRelations.map((componentRelation) => {
            return (
              <NavItemComponent
                key={componentRelation.child_id}
                id={componentRelation.child_id}
              />
            );
          })}
        </ul>
      </Div>
    </>
  );
}
