import React from "react";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { CartItemRow } from "./CartItemRow";
import CloseIcon from "@mui/icons-material/Close";
import "./ShoppingCart.css";
import { pf, getPageLocaleUrl } from "../utilities/helpers";
import usePageLanguage from "../hooks/usePageLanguage";
import { useAppSelector } from "../../../store/hooks";

type ShoppingCartProps = {
  isOpen: boolean;
};

const languageTranslations = {
  et: {
    total: "KOKKU",
    cart: "OSTUKORV",
    confirm_order: "VORMISTA TELLIMUS",
    sum: "Summa",
    discount: "Kohandus",
    delivery: "Tarne",
  },
  en: {
    total: "TOTAL",
    cart: "CART",
    confirm_order: "PROCEED TO CHECKOUT",
    sum: "Sum",
    discount: "Adjustment",
    delivery: "Delivery",
  },
};

export default function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const {
    closeCart,
    locationProducts,
    cartItems,
    increaseCartQuantity,
    orderDeliveryMethod,
    totalCartDiscount,
    totalCartSum,
  } = useShoppingCart();

  const cartSidepanel =
    React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const locale = usePageLanguage();
  const pages = useAppSelector((state) => state.builder.pages.list);

  const checkoutPage = pages.find(
    (page) => page.page_type === "checkout" && page.language.locale === locale
  );
  var checkoutHref = checkoutPage
    ? getPageLocaleUrl(checkoutPage)
    : "/et/checkout";
  if (checkoutHref.substring(0, 1) !== "/") {
    checkoutHref = "/" + checkoutHref;
  }

  const deliveryPrice =
    orderDeliveryMethod && orderDeliveryMethod.price && cartItems.length > 0
      ? Number(orderDeliveryMethod?.price)
      : 0;

  const closeCartSidepanel = (e: any) => {
    if (cartSidepanel.current && !cartSidepanel.current.contains(e.target)) {
      closeCart();
    }
  };
  document.addEventListener("mousedown", closeCartSidepanel);

  return (
    <div ref={cartSidepanel} className="cart-sidePanel">
      <div className="sidePanels">
        <div
          id="cartSidepanel"
          className={`sidePanel${isOpen ? " visible" : ""}`}
        >
          <div style={{ fontSize: "21px", fontWeight: "400" }}>
            <span
              className="panel-title inline-block"
              style={{
                fontWeight: "600",
                fontSize: "18px",
                fontFamily: "Roboto, sans-serif",
                lineHeight: "21.6px",
              }}
            >
              {languageTranslations[locale].cart}
            </span>
            <CloseIcon
              className="btn-pure clickable2 padding-5 pull-right"
              onClick={() => closeCart()}
            />
          </div>
          <div
            className=""
            style={{ height: "calc(100% - 59px)", overflowX: "auto" }}
          >
            <div
              className="table-container padding-top-20"
              style={{
                overflowX: "auto",
                maxHeight: "calc(100% - 200px)",
                minHeight: "150px !important",
              }}
            >
              <table className="table tablerow-no-border cartTable">
                <tbody>
                  {cartItems.map((item) => (
                    <CartItemRow key={item.id} {...item} />
                  ))}
                </tbody>
              </table>
            </div>

            {orderDeliveryMethod?.id || totalCartDiscount > 0 ? (
              <div className="padding-top-20">
                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                  {languageTranslations[locale].sum}:
                  <span className="pull-right">
                    <span className="cartTotal">{pf(totalCartSum)}</span> &euro;
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}
            {totalCartDiscount > 0 && (
              <div className="">
                <span
                  className="discount-price-color"
                  style={{ fontWeight: "400", fontSize: "14px" }}
                >
                  {languageTranslations[locale].discount}:
                  <span className="pull-right">
                    <span className="cartTotal">-{pf(totalCartDiscount)}</span>{" "}
                    &euro;
                  </span>
                </span>
              </div>
            )}
            {orderDeliveryMethod?.id && cartItems.length > 0 && (
              <div className="">
                <div style={{ fontWeight: "400", fontSize: "14px" }}>
                  {languageTranslations[locale].delivery}:
                  <span className="pull-right">
                    <span className="cartTotal">{pf(deliveryPrice)}</span>{" "}
                    &euro;
                  </span>
                </div>
              </div>
            )}

            <div className="">
              <div style={{ fontWeight: "400", fontSize: "18px" }}>
                {languageTranslations[locale].total}:
                <span className="pull-right">
                  <span className="cartTotal">
                    {pf(
                      totalCartSum -
                        totalCartDiscount +
                        (orderDeliveryMethod ? deliveryPrice : 0)
                    )}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
            <div className="padding-top-20">
              <a
                className="btn btn-primary shop-controlled-border-radius confirm-order-button"
                href={checkoutHref}
                style={{ width: "100%", color: "#fff" }}
              >
                {languageTranslations[locale].confirm_order}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
