import React, { useEffect, useRef, useState } from "react";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";
import { defaultComponents } from "./interfaces/DefaultComponents";
import "./AccordionComponent.css";
import { ExpandMore } from "@mui/icons-material";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function AccordionComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();
  const colors = useAppSelector((state) => state.builder.colors);

  const templates = useAppSelector((state) => state.builder.templates);

  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const isBuilder = document.getElementById("BuilderContent");

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | "auto">(0);

  const [expanded, setExpanded] = React.useState(
    isBuilder
      ? true
      : component.attributes.default_expanded
      ? component.attributes.default_expanded
      : false
  );

  function handleClick() {
    setExpanded(!expanded);
  }

  const renderChildren = () => {
    const accordionTemplate = templates.find(
      (x) => x.id === component.attributes.template_id
    )?.webpage_component_id;

    const accordionComponentId = component.childWebpageComponentRelations.find(
      (x) => x.id === accordionTemplate
    )?.child_id;

    let ComponentToRender;

    if (accordionTemplate) {
      const accordionComponent = components[accordionTemplate!];

      if (accordionTemplate && accordionComponent) {
        ComponentToRender =
          defaultComponents[components[accordionTemplate!].type];

        return (
          <>
            <ComponentToRender
              key={accordionComponentId}
              {...accordionComponent}
            />
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    // Measure the content height
    const measureHeight = () => {
      if (contentRef.current) {
        const columnElement =
          contentRef.current.querySelector(".col")?.clientHeight;

        const sectionElement =
          contentRef.current.querySelector(".row")?.clientHeight;

        const containerElement = contentRef.current.querySelector(
          ".container.g-0"
        ) as HTMLElement;

        if (sectionElement && !columnElement && containerElement) {
          setContentHeight(sectionElement);
          containerElement.style.height = `${sectionElement}px`;
        } else if (!sectionElement && columnElement && containerElement) {
          containerElement.style.height = `${columnElement}px`;
          setContentHeight(columnElement);
        } else if (sectionElement && columnElement && containerElement) {
          containerElement.style.height = `${Math.max(
            sectionElement,
            columnElement
          )}px`;
          console.log("THIS RAN,", Math.max(sectionElement, columnElement));

          setContentHeight(Math.max(sectionElement, columnElement));
        }
      }
    };

    // Measure the height initially
    measureHeight();
  }, [contentRef.current, expanded]);

  useEffect(() => {
    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "none"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  return (
    <>
      <Div
        data-style={{
          ...backgroundStyles,
          backgroundColor: backgroundStyles?.backgroundColor
            ? backgroundStyles?.backgroundColor
            : "none",
        }}
        id={id.toString()}
        data-component-type="accordion"
        className={
          (attributes.classes ?? "") +
          " builder-component builder-component__accordion" +
          (editorId === id ? " builder-component--active" : "")
        }
        style={{
          ...attributes.styles,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
          }}
          className="accordion-header"
          onClick={handleClick}
        >
          <RenderChildren
            key={id + "children"}
            childWebpageComponentRelations={childWebpageComponentRelations.filter(
              (x) => x.sort === 1
            )}
          />

          <ExpandMore
            onClick={handleClick}
            className="accordion-icon"
            style={{
              position: "absolute",
              right:
                component.attributes.icon_position === "right" ? "0px" : "0px",
              left:
                component.attributes.icon_position === "left" ? "0px" : "auto",
              fontSize: component.attributes.icon_size
                ? component.attributes.icon_size + "px"
                : "18px",
              top: "50%",
              color: component.attributes.icon_color ?? "#000",
              transform: `translateY(-50%) rotate(${expanded ? 180 : 0}deg)`,
              transition: "all 0.25s",
            }}
          />
        </div>

        <div
          ref={contentRef}
          className={`accordion-content`}
          style={{
            transform: `translateY(${expanded ? "20px" : ""})`,
            overflow: "hidden",
            maxHeight: expanded ? contentHeight : 0,
            height: expanded ? contentHeight : 0,
            opacity: expanded ? 1 : 0, // Fade in/out effect
            transition:
              "max-height 0.25s ease, height 0.25s ease, opacity 0.25s ease",
            justifyContent: component.attributes.align_content
              ? component.attributes.align_content
              : "start",
          }}
        >
          {renderChildren()}
        </div>
        {/* <style>
          {`
         .accordion-content .container.g-0  {
            height: ${Number(contentHeight) + 5}px!important;
          }
        `}
        </style> */}
      </Div>
    </>
  );
}
