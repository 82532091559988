import { useState } from "react";
import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import CheckoutPaymentMethodButton from "./CheckoutPaymentMethodButton";
import WestIcon from "@mui/icons-material/West";
import CheckoutConfirmModal from "./CheckoutConfirmModal";
import CheckoutSelectDeliveryMethodModal from "./CheckoutSelectDeliveryMethodModal";
import CheckoutSelectPaymentMethodModal from "./CheckoutSelectPaymentMethodModal";
import usePageLanguage from "../hooks/usePageLanguage";

interface Props {
  setShowBuyerModal: (val: boolean) => void;
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void;
  setShowDeliveryModal: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    payment_method: "MAKSEVIIS",
    back_to_payment: "Tagasi makseviisi valikusse",
    confirm_order:
      "Kohe pärast tellimuse kinnitamist ja tingimustega nõustumist suunatakse edasi internetipanka makset sooritama.",
    submit_order: "KINNITA TELLIMUS",
  },
  en: {
    payment_method: "PAYMENT METHOD",
    back_to_payment: "Back to the payment methods",
    confirm_order:
      "Immediately after confirming the order and accepting the terms, you will be forwarded to the online bank to make the payment.",
    submit_order: "SUBMIT ORDER",
  },
};

export default function CheckoutPaymentPanel({
  setShowBuyerModal,
  setModalDeliveryMethod,
  setShowDeliveryModal,
}: Props) {
  const [showCheckoutConfirmModal, setShowCheckoutConfirmModal] =
    useState(false);
  const [showSelectDeliveryMethodModal, setShowSelectDeliveryMethodModal] =
    useState(false);
  const [showSelectPaymentMethodModal, setShowSelectPaymentMethodModal] =
    useState(false);
  const {
    paymentMethods,
    orderPaymentMethod,
    orderDeliveryMethod,
    setOrderPaymentMethod,
    buyer,
  } = useShoppingCart();

  const locale = usePageLanguage();

  const confirmClick = () => {
    if (!buyer) {
      setShowBuyerModal(true);
      return false;
    }

    if (buyer?.type !== "organization" && !buyer.firstname) {
      setShowBuyerModal(true);
      return false;
    }

    if (buyer?.type === "organization" && !buyer.organization_name) {
      setShowBuyerModal(true);
      return false;
    }

    if (!orderDeliveryMethod || !orderDeliveryMethod.id) {
      setShowSelectDeliveryMethodModal(true);
      return false;
    }
    if (!orderPaymentMethod || !orderPaymentMethod.id) {
      setShowSelectPaymentMethodModal(true);
      return false;
    }
    setShowCheckoutConfirmModal(true);
  };
  return (
    <div className="panel" id="checkoutPaymentmethodPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">4</span>
          <span className="panel-title-text">
            {languageTranslations[locale].payment_method}
          </span>
        </div>
      </div>
      <div className="panel-body">
        {!orderPaymentMethod || !orderPaymentMethod.id ? (
          <div className="paymentMethodNotSelected">
            {paymentMethods
              ? paymentMethods.map((pm) => (
                  <CheckoutPaymentMethodButton key={pm.id} paymentMethod={pm} />
                ))
              : ""}
          </div>
        ) : (
          <div className="row paymentMethodSelected">
            <div className="col-xs-12">
              <button
                className="btn btn-pure backToPaymentMethodSelect"
                onClick={() => setOrderPaymentMethod(null)}
              >
                <WestIcon className="icon" style={{ fontSize: "14px" }} />{" "}
                <span>{languageTranslations[locale].back_to_payment}</span>
              </button>
            </div>
            <div className="col-xs-12 selectedPaymentMethodButtonConainer">
              <CheckoutPaymentMethodButton
                key={orderPaymentMethod.id}
                paymentMethod={orderPaymentMethod}
              />
            </div>
            <div className="col-xs-12" style={{ fontSize: "15px" }}>
              {languageTranslations[locale].confirm_order}
            </div>
          </div>
        )}
      </div>
      <div className="panel-footer text-center">
        <button
          className="btn btn-primary checkoutConfirmOrderButton shop-controlled-border-radius"
          style={{ paddingTop: "6px", paddingBottom: "6px" }}
          onClick={confirmClick}
        >
          {languageTranslations[locale].submit_order}
        </button>
      </div>
      {showCheckoutConfirmModal && (
        <CheckoutConfirmModal
          show={showCheckoutConfirmModal}
          setShow={setShowCheckoutConfirmModal}
        />
      )}
      {showSelectDeliveryMethodModal && (
        <CheckoutSelectDeliveryMethodModal
          show={showSelectDeliveryMethodModal}
          setShow={setShowSelectDeliveryMethodModal}
          setModalDeliveryMethod={setModalDeliveryMethod}
          setShowDeliveryModal={setShowDeliveryModal}
        />
      )}
      {showSelectPaymentMethodModal && (
        <CheckoutSelectPaymentMethodModal
          show={showSelectPaymentMethodModal}
          setShow={setShowSelectPaymentMethodModal}
        />
      )}
    </div>
  );
}
