import { memo } from "react";
import { useAppSelector } from "../../store/hooks";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import "./Default.css";

declare var defaultComponents: any;

function ContentRenderWebpageComponent({ componentId }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[componentId]
  );

  if (!component) {
    return <></>;
  }
  if (!defaultComponents[component.type]) {
    return <></>;
  }
  const ComponentToRender = defaultComponents[component.type];

  // @ts-ignore
  return (
    <ErrorFallbackComponent
      key={component.id + "_errorBoundary_"}
      showHomeButton={true}
    >
      <ComponentToRender {...component} key={component.id} />
    </ErrorFallbackComponent>
  );
}

export default memo(ContentRenderWebpageComponent);
