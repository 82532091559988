import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";

import { defaultComponents } from "./interfaces/DefaultComponents";

function CookiePolicyComponent() {
  const cookieTemplates = useAppSelector(
    (state) => state.builder.templates
  ).filter((x) => x.use === "cookiePolicy");
  const [isVisible, setIsVisibile] = useState(true);
  const activePage = useAppSelector((state) => state.builder.pages.active);
  const pageLanguageId = useAppSelector((state) =>
    state.builder.pages.list.find((x) => x.id === activePage)
  )?.website_language_id;
  const cookieTemplate = cookieTemplates.find(
    (x) => x.language_id === pageLanguageId
  );
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );
  let cookieTemplateComponent;

  if (cookieTemplate && cookieTemplate?.webpage_component_id && components) {
    cookieTemplateComponent = components[cookieTemplate?.webpage_component_id];
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleCookiePolicyShow = () => {
    // Check localstorage if user has agreed to cookies

    const deleteCookie = (cname) => {
      const d = new Date();

      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=; " + expires + "; path=/";
    };

    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date();

      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    };

    deleteCookie("user_cookie_consent");
    setCookie("user_cookie_consent", 1, 30);

    setIsVisibile(false);
  };

  const getElementByClassAsync = (className) => {
    return new Promise((resolve) => {
      const getElement = () => {
        const elements = document.querySelectorAll(`.${className} button`);

        if (elements.length > 0) {
          resolve(elements);
        } else {
          requestAnimationFrame(getElement);
        }
      };

      getElement();
    });
  };

  const componentDidMount = async () => {
    const cookieButtons = await getElementByClassAsync("cookie-policy-popup");

    (cookieButtons as NodeListOf<HTMLButtonElement>).forEach((button) => {
      button.addEventListener("click", handleCookiePolicyShow);
    });

    return () => {
      (cookieButtons as NodeListOf<HTMLButtonElement>).forEach((button) => {
        button.removeEventListener("click", handleCookiePolicyShow);
      });
    };
  };

  useEffect(() => {
    componentDidMount();
  }, [activePage]);

  let cookie_consent = getCookie("user_cookie_consent");

  if (!cookieTemplateComponent) {
    return <></>;
  }

  const ComponentToRender = defaultComponents[cookieTemplateComponent.type];

  if (
    cookie_consent != "" &&
    cookieTemplateComponent.id &&
    cookieTemplateComponent
  ) {
    return <></>;
  } else {
    return (
      <>
        <style>
          {`
      .cookie-policy-popup .container .col {
        pointer-events: all;
      }
    `}
        </style>

        <div
          className="cookie-policy-popup"
          style={{
            position: isVisible ? "fixed" : "unset",
            bottom: "0",
            zIndex: 9,
            width: "100%",
            display: isVisible ? "block" : "none",
            pointerEvents: "none",
          }}
        >
          <ComponentToRender
            {...cookieTemplateComponent}
            key={cookieTemplateComponent?.id}
          />
        </div>
      </>
    );
  }
}

export default CookiePolicyComponent;
