import { useState } from "react";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { pf } from "../utilities/helpers";
import { useAppSelector } from "../../../store/hooks";
import usePageLanguage from "../hooks/usePageLanguage";
import ProductImagesComponent from "./ProductImagesComponent";

export default function ProductPanel({ locationProduct, id }: any) {
  const locale = usePageLanguage();
  const { increaseCartQuantity } = useShoppingCart();
  const [tab, setTab] = useState("descriptionTab");

  const locationProductTranslations = locationProduct?.translations?.[locale];

  // Don't move this outside of the function
  const languageTranslations = {
    et: {
      add_to_cart: "LISA OSTUKORVI",
      description: "Kirjeldus",
      delivery: "Tarne",
      delivery_time: `Tarneaeg laos: ${locationProduct.deliveryTime?.days} päeva`,
      delivery_time_with_ordering: `Tarneaeg tellimisel:
      ${locationProduct.deliveryTimeWithOrdering?.days} päeva`,
    },
    en: {
      add_to_cart: "ADD TO CART",
      description: "Description",
      delivery: "Delivery",
      delivery_time: `Delivery time from warehouse: ${locationProduct.deliveryTime?.days} days`,
      delivery_time_with_ordering: `Delivery time ordering:
      ${locationProduct.deliveryTimeWithOrdering?.days} days`,
    },
  };

  return (
    <div className="panel" id="productPanel">
      <div className="panel-body container">
        <div className="col-xs-12">
          <div className="imagesContainer">
            <h1 className="product-name">{locationProductTranslations.name}</h1>
            <div className="web-product-image">
              <ProductImagesComponent
                id={id}
                slides={locationProduct.product.product_files}
                options={{ align: "start" }}
              />
            </div>
          </div>
          <div className="dataContainer">
            <h1 className="product-name inline-block">
              {locationProductTranslations.name}
            </h1>
            <div
              className="productPrice"
              style={
                locationProduct.adjustments &&
                locationProduct.adjustments?.length > 0
                  ? { textDecoration: "line-through", lineHeight: "1" }
                  : {}
              }
            >
              {pf(locationProduct.price_total)} &euro;
            </div>

            {locationProduct.price_with_discounts &&
            locationProduct.adjustments?.length > 0 ? (
              <div className="discount_price_box shop-controlled-border-radius discount-price-color">
                {pf(locationProduct.price_with_discounts)} &euro;{" "}
                {locationProduct.adjustments?.map((adjustment, i) => {
                  const string = `${i > 0 ? ", " : ""}${
                    adjustment.display_name
                  } ${adjustment.value.toString().startsWith("-") ? "" : "+"}${
                    adjustment.value
                  }%`;

                  return string;
                })}
              </div>
            ) : (
              ""
            )}

            <div>
              <button
                className="btn btn-primary addProductToCart shop-controlled-border-radius"
                style={{ fontSize: "14px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  increaseCartQuantity(locationProduct.id);
                }}
              >
                {languageTranslations[locale].add_to_cart}
              </button>
            </div>
            <div className="pt-15">
              <div className="nav-tabs-horizontal" data-plugin="tabs">
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link${
                        tab == "descriptionTab" ? " active" : ""
                      }`}
                      onClick={() => {
                        setTab("descriptionTab");
                      }}
                      href={undefined}
                      aria-controls="descriptionTab"
                      role="tab"
                    >
                      {languageTranslations[locale].description}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link${
                        tab == "deliveryTab" ? " active" : ""
                      }`}
                      onClick={() => {
                        setTab("deliveryTab");
                      }}
                      href={undefined}
                      aria-controls="deliveryTab"
                      role="tab"
                    >
                      {languageTranslations[locale].delivery}
                    </a>
                  </li>
                </ul>
                <div className="tab-content pt-20">
                  <div
                    className={`tab-pane text-left${
                      tab == "descriptionTab" ? " active" : ""
                    }`}
                    id="descriptionTab"
                    role="tabpanel"
                  >
                    <div
                      style={{ fontSize: "16px" }}
                      dangerouslySetInnerHTML={{
                        __html: locationProductTranslations.description,
                      }}
                    ></div>
                  </div>

                  <div
                    className={`tab-pane text-left${
                      tab == "deliveryTab" ? " active" : ""
                    }`}
                    id="deliveryTab"
                    role="tabpanel"
                  >
                    {locationProduct.deliveryTime && (
                      <div className="deliveryTime">
                        {languageTranslations[locale].delivery_time}
                      </div>
                    )}
                    {locationProduct.deliveryTimeWithOrdering && (
                      <div className="deliveryTimeWithOrdering">
                        {
                          languageTranslations[locale]
                            .delivery_time_with_ordering
                        }
                      </div>
                    )}
                    <div>{locationProductTranslations.deliveryInfo}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
