import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import { Link, useNavigate } from "react-router-dom";
import Image from "@mui/icons-material/Image";
import useLazyLoad from "./utilities/useLazyLoad";
import { getPageLocaleUrl } from "./utilities/helpers";
import MegaMenuComponent from "./MegaMenuComponent";

export default function ImageComponent({
  id,
  attributes,
  childWebpageComponentRelations,
}: any) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const isBuilder = document.getElementById("BuilderContent");
  const pages = useAppSelector((state) => state.builder.pages);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const urlRegex = /url\("(.+)"\)/;
  const imageSrc = attributes.styles?.backgroundImage?.match(urlRegex);
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;
  const [backgroundImage, setBackgroundImage] = useState(
    component.attributes.styles.backgroundImage
      ? component.attributes.styles.backgroundImage
      : ""
  );
  const imageElementRef = useRef<HTMLDivElement>(null);
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const colors = useAppSelector((state) => state.builder.colors);

  useEffect(() => {
    if (component.attributes.styles.backgroundImage) {
      setBackgroundImage(component.attributes.styles.backgroundImage);
    }
  }, [component.attributes.styles.backgroundImage]);

  const loaded = useLazyLoad(imageElementRef, backgroundImage);

  const handleMegaMenuOpen = (boolean) => {
    if (attributes.link?.type === "MegaMenu") {
      setMegaMenuOpen(boolean);
    }
  };

  const navigate = useNavigate();

  let href;

  if (attributes.link?.type === "Page" && attributes.link?.pageId) {
    const page = pages.list.find((x) => x.id === attributes.link?.pageId);

    if (page) {
      href = getPageLocaleUrl(page);
    }
  } else if (attributes.link?.type === "Web_out") {
    if (!attributes.link.url.includes("http")) {
      href = `https://${attributes.link?.url}`;
    } else {
      href = attributes.link.url;
    }
  } else if (attributes.link?.type === "Web") {
    if (attributes.link?.url.startsWith("/")) {
      href = attributes.link?.url;
    } else {
      href = "/" + attributes.link?.url;
    }
  } else if (attributes.link?.type === "None") {
    href = "";
  } else if (attributes.link?.type === "Anchor") {
    const anchorLink = attributes.link.url.startsWith("#")
      ? attributes.link.url
      : `#${attributes.link.url}`;
    href = anchorLink;
  } else if (attributes.link?.type === "Phone") {
    if (attributes.link.url.startsWith("tel:")) {
      href = attributes.link.url;
    } else {
      href = `tel:${attributes.link.url}`;
    }
  } else if (attributes.link?.type === "Email") {
    if (attributes.link.url.startsWith("mailto:")) {
      href = attributes.link.url;
    } else {
      href = `mailto:${attributes.link.url}`;
    }
  } else {
    href = "";
  }

  const [selectedTopPosition, setSelectedTopPosition] = useState(
    component.attributes.backgroundVideo?.topPosition
      ? component.attributes.backgroundVideo?.topPosition
      : "50"
  );

  const [selectedExtension, setSelectedExtension] = useState(
    component.attributes.backgroundVideo?.extension
      ? component.attributes.backgroundVideo?.extension
      : "mp4"
  );

  const [selectedLeftPosition, setSelectedLeftPosition] = useState(
    component.attributes.backgroundVideo?.leftPosition
      ? component.attributes.backgroundVideo?.leftPosition
      : "50"
  );
  const [selectedWidthPosition, setSelectedWidthPosition] = useState(
    component.attributes.backgroundVideo?.width
      ? component.attributes.backgroundVideo?.width
      : "120"
  );
  const [selectedHeightPosition, setSelectedHeightPosition] = useState(
    component.attributes.backgroundVideo?.height
      ? component.attributes.backgroundVideo?.height
      : "120"
  );
  const [selectedAutoplay, setSelectedAutoplay] = useState<boolean>(
    component.attributes.backgroundVideo?.autoplay
      ? component.attributes.backgroundVideo?.autoplay
      : true
  );
  const [selectedLoop, setSelectedLoop] = useState<boolean>(
    component.attributes.backgroundVideo?.loop
      ? component.attributes.backgroundVideo?.loop
      : true
  );
  const [selectedMuted, setSelectedMuted] = useState<boolean>(
    component.attributes.backgroundVideo?.muted
      ? component.attributes.backgroundVideo?.muted
      : true
  );

  const [selectedControls, setSelectedControls] = useState<boolean>(
    component.attributes.backgroundVideo?.controls
      ? component.attributes.backgroundVideo?.controls
      : true
  );

  const [selectedLazyLoad, setSelectedLazyLoad] = useState<boolean>(
    component.attributes.backgroundVideo?.lazyLoad
      ? component.attributes.backgroundVideo?.lazyLoad
      : false
  );

  useEffect(() => {
    setSelectedTopPosition(
      component.attributes.backgroundVideo?.topPosition
        ? component.attributes.backgroundVideo?.topPosition
        : "50"
    );
    setSelectedLeftPosition(
      component.attributes.backgroundVideo?.leftPosition
        ? component.attributes.backgroundVideo?.leftPosition
        : "50"
    );
    setSelectedWidthPosition(
      component.attributes.backgroundVideo?.width
        ? component.attributes.backgroundVideo?.width
        : "120"
    );
    setSelectedHeightPosition(
      component.attributes.backgroundVideo?.height
        ? component.attributes.backgroundVideo?.height
        : "120"
    );
    setSelectedAutoplay(
      component.attributes.backgroundVideo?.hasOwnProperty("autoplay")
        ? component.attributes.backgroundVideo?.autoplay
        : true
    );
    setSelectedLoop(
      component.attributes.backgroundVideo?.hasOwnProperty("loop")
        ? component.attributes.backgroundVideo?.loop
        : true
    );
    setSelectedMuted(
      component.attributes.backgroundVideo?.hasOwnProperty("muted")
        ? component.attributes.backgroundVideo?.muted
        : true
    );

    setSelectedControls(
      component.attributes.backgroundVideo?.hasOwnProperty("controls")
        ? component.attributes.backgroundVideo?.controls
        : false
    );

    setSelectedLazyLoad(
      component.attributes.backgroundVideo?.hasOwnProperty("lazyLoad")
        ? component.attributes.backgroundVideo?.lazyLoad
        : false
    );

    setSelectedExtension(
      component.attributes.backgroundVideo?.hasOwnProperty("extension")
        ? component.attributes.backgroundVideo?.extension
        : "mp4"
    );
  }, [component.attributes.backgroundVideo]);

  const selectedMaintainAspectRatio =
    !!component.attributes.maintainAspectRatio;

  useEffect(() => {
    const adjustHeight = () => {
      if (!imageElementRef.current) return;

      const aspectRatio =
        imageElementRef.current.offsetHeight /
        imageElementRef.current.offsetWidth;

      if (
        !component.attributes.backgroundVideo &&
        !component.attributes.styles.backgroundImage
      ) {
        return;
      }

      if (!selectedMaintainAspectRatio) {
        return;
      }

      let urlMatch;

      if (
        !component.attributes.styles.backgroundImage &&
        component.attributes.backgroundVideo.url
      ) {
        urlMatch = component.attributes.backgroundVideo?.url;
      } else if (component.attributes.styles.backgroundImage) {
        urlMatch =
          component.attributes.styles.backgroundImage.match(/url\("([^"]+)"\)/);
      }

      if (!urlMatch || urlMatch === "") {
        return;
      }

      if (
        component.attributes.backgroundVideo &&
        component.attributes.backgroundVideo.url &&
        !component.attributes.styles.backgroundImage
      ) {
        const videoAspectRatio = 0.5625;
        if (aspectRatio > videoAspectRatio && imageElementRef.current) {
          imageElementRef.current.style.height = `${
            imageElementRef.current.offsetWidth * videoAspectRatio
          }px`;
        } else if (aspectRatio < videoAspectRatio && imageElementRef.current) {
          imageElementRef.current.style.height = `${
            imageElementRef.current.offsetWidth * videoAspectRatio
          }px`;
        }
      } else {
        const image = document.createElement("img");
        image.src = urlMatch ? urlMatch[1] : backgroundImage;

        image.onload = () => {
          const imageAspectRatio = image.height / image.width;

          if (aspectRatio > imageAspectRatio && imageElementRef.current) {
            imageElementRef.current.style.height = `${
              imageElementRef.current.offsetWidth * imageAspectRatio
            }px`;
          } else if (
            aspectRatio < imageAspectRatio &&
            imageElementRef.current
          ) {
            imageElementRef.current.style.height = `${
              imageElementRef.current.offsetWidth * imageAspectRatio
            }px`;
          }
        };
      }
    };

    adjustHeight();

    const handleResize = () => {
      adjustHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    selectedMaintainAspectRatio,
    component.attributes.styles.backgroundImage,
    backgroundImage,
    component,
    component.attributes?.backgroundVideo?.url,
    imageElementRef,
  ]);

  useEffect(() => {
    console.log(component, "THIS IS THE COMPONENT");

    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "#none"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);

    imageElementRef.current?.style.setProperty(
      "background-color",
      `${backgroundColor ? backgroundColor : "none"}`
    );
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  function generateLink(link: any) {
    let href;

    if (link?.type === "Page" && link?.pageId) {
      const page = pages.list.find((x) => x.id === link.pageId);

      if (page) {
        href = getPageLocaleUrl(page);
      }
    } else if (link?.type === "Web_out") {
      if (!link.url.includes("http")) {
        href = `https://${link?.url}`;
      } else {
        href = link.url;
      }
    } else if (link?.type === "Web") {
      if (link.url.startsWith("/")) {
        href = link?.url;
      } else {
        href = "/" + link.url;
      }
    } else if (link?.type === "None") {
      href = "";
    } else if (link?.type === "Anchor") {
      const anchorLink = link.url.startsWith("#") ? link.url : `#${link.url}`;
      href = anchorLink;
    } else if (link.type === "Phone") {
      if (link.url.startsWith("tel:")) {
        href = link.url;
      } else {
        href = `tel:${link.url}`;
      }
    } else if (link.type === "Email") {
      if (link.url.startsWith("mailto:")) {
        href = link.url;
      } else {
        href = `mailto:${link.url}`;
      }
    }

    return link.type === "Page" ? (
      <Link
        className="button-link"
        key={link.text + link.type + href}
        to={`${href}`} // Avoid redirecting when editing
        target={link.type !== "None" ? link.target : "sefsefes"}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
      ></Link>
    ) : (
      <a
        className="button-link"
        key={link.text + link.type + href}
        href={href}
        target={
          link.type !== "None" && link.type !== "" ? link.target : "_self"
        }
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
        onClick={() => {}}
        style={{
          zIndex: 8,
        }}
      ></a>
    );
  }

  return (
    <div
      ref={imageElementRef}
      id={id.toString()}
      data-component-type="image"
      className={
        (attributes.classes ?? "") +
        " builder-component relative builder-component__image " +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        background: "none",
        ...attributes.styles,
        backgroundColor: backgroundStyles?.backgroundColor,

        width:
          attributes.styles.width === "0px" ||
          attributes.styles.width === "0%" ||
          attributes.styles.width === "0" ||
          attributes.styles.width === "px" ||
          attributes.styles.width === "%"
            ? "300px"
            : attributes.styles.width ?? "300px",
        height: selectedMaintainAspectRatio
          ? "auto"
          : component.attributes.styles.height
          ? component.attributes.styles.height
          : "300px",

        display: "inline-grid",
        backgroundImage: "",

        margin: "0px!important",
        position: "relative",
        cursor:
          href !== "" && href !== undefined && href.trim() !== ""
            ? "pointer"
            : "default",
      }}
      onClick={() => {
        if (attributes.link?.type === "MegaMenu") {
          handleMegaMenuOpen(!megaMenuOpen);
        }

        if (isBuilder) {
          return;
        }

        if (href !== "" && href !== undefined && href.trim() !== "") {
          if (attributes.link?.target === "_blank") {
            window.open(href, "_blank");
            return;
          } else if (attributes.link?.type !== "Web_out") {
            navigate(href);
            window.scrollTo(0, 0);
          } else if (attributes.link?.type === "Web_out") {
            window.location.href = href;
          }
        }
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        handleMegaMenuOpen(true);

        // setMegaMenuOpen(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();

        handleMegaMenuOpen(false);

        // setMegaMenuOpen(false);
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />

      {attributes &&
        attributes.link &&
        attributes.link.type === "MegaMenu" &&
        attributes.link.template_id !== null && (
          <>
            <MegaMenuComponent
              id={attributes.link.template_id}
              parentId={id}
              menuOpen={megaMenuOpen}
              setMegaMenuOpen={setMegaMenuOpen}
              leftPosition={attributes.link?.left_position}
              topPosition={attributes.link?.top_position}
              activateType={attributes.link?.activate_type}
            />
          </>
        )}

      {attributes &&
        attributes.link &&
        attributes.link.type === "Dropdown" &&
        attributes.links &&
        attributes.links.length > 0 && (
          <>
            <div className="button-subnav">
              {attributes.links && attributes.links.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {attributes.links.map((item, i) => generateLink(item))}
                </div>
              )}
            </div>
          </>
        )}

      {component.attributes.backgroundVideo && (
        <>
          {selectedExtension === "mp4" && (
            <>
              <video
                autoPlay={selectedAutoplay}
                muted={selectedMuted}
                loop={selectedLoop}
                controls={selectedControls}
                style={{
                  zIndex: -1,
                  position: "absolute",
                  top: `${selectedTopPosition}%`,
                  left: `${selectedLeftPosition}%`,
                  height: "100%",
                  width: "100%",

                  transform: "translate(-50%, -50%)",
                }}
                src={component.attributes.backgroundVideo.url}
              />
            </>
          )}

          {(selectedExtension === "youtube" || selectedExtension === "vimeo") &&
            component.attributes.backgroundVideo &&
            component.attributes.backgroundVideo.extension !== "mp4" && (
              <iframe
                loading={selectedLazyLoad ? "lazy" : "eager"}
                scrolling="no"
                onClick={(e) => {
                  if (isBuilder) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                frameBorder="0"
                style={{
                  zIndex: -1,
                  position: "absolute",
                  top: `${selectedTopPosition}%`,
                  left: `${selectedLeftPosition}%`,
                  height: "100%",
                  width: "100%",
                  transform: "translate(-50%, -50%)",
                }}
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                src={`${
                  component.attributes.backgroundVideo.url
                }?api=1&autoplay=${
                  selectedAutoplay === true ? "1" : "0"
                }&loop=${
                  selectedLoop === true ? "1" : "0"
                }&byline=0&title=0&muted=${
                  selectedMuted === true ? "1" : "0"
                }&controls=${selectedControls === true ? "1" : "0"}&mute=${
                  selectedMuted === true ? "1" : "0"
                }&playlist=${
                  component.attributes.backgroundVideo.url.split("/embed/")[1]
                }`}
              />
            )}
        </>
      )}

      {imageSrc && (
        <div
          className="builder-image--loaded"
          style={{
            all: "",

            ...attributes.styles,
            backgroundColor: "none",

            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",

            paddingLeft: attributes.styles?.paddingLeft ?? "0px",
            paddingRight: attributes.styles?.paddingRight ?? "0px",
            paddingTop: attributes.styles?.paddingTop ?? "0px",
            paddingBottom: attributes.styles?.paddingBottom ?? "0px",

            backgroundAttachment:
              attributes.styles?.backgroundAttachment ?? "scroll",
            backgroundImage: loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none",
            backgroundOrigin:
              attributes.styles?.backgroundOrigin ?? "padding-box",
            backgroundPosition:
              attributes.styles?.backgroundPosition ?? "center center",
            backgroundRepeat:
              attributes.styles?.backgroundRepeat ?? "no-repeat",
            backgroundSize: attributes.styles?.backgroundSize ?? "cover",
            width: "100%",
            height: "100%",

            // width: attributes.styles?.width ?? "300px",
            // height: attributes.styles?.height ?? "250px",
          }}
          data-style={
            attributes && attributes.styles
              ? {
                  ...attributes.styles,

                  ...backgroundStyles,
                  backgroundColor: backgroundStyles?.backgroundColor,

                  backgroundImage: "none",
                }
              : {}
          }
        />
      )}

      {!attributes.styles.backgroundImage && !attributes.backgroundVideo && (
        <div
          className=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.1)",
          }}
        >
          <>
            <Image style={{ fontSize: "60px" }} />
            No image selected.
          </>
        </div>
      )}
    </div>
  );
}
