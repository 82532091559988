import { SeparatorComponent as SeparatorComponent_ } from "./interfaces/base";

interface Props {
  id: number,
  attributes: SeparatorComponent_['attributes']
}

export default function SeparatorComponent({
  id,
  attributes
}: Props) {
  return (
    <hr
      id={id.toString()}
      style={attributes.styles}
    ></hr>
  );
}