import { InputComponent as InputComponent_ } from "./interfaces/base";

interface Props {
  id: number;
  attributes: InputComponent_["attributes"];
  editor: JSX.Element;
}

export default function InputComponent({ id, attributes }: Props) {
  return attributes.text_area ? (
    <textarea
      required={attributes.required ?? false}
      name={attributes.name}
      placeholder={attributes.placeholder}
      style={{ width: "100%" }}
      className={
        "form-control"
      } /*builder-component builder-component__text-editor" + (editorId === id ? " builder-component--active" : "")}*/
    />
  ) : (
    <input
      required={attributes.required ?? false}
      name={attributes.name}
      type="text"
      placeholder={attributes.placeholder}
      style={{ width: "100%" }}
      className={
        "form-control"
      } /*builder-component builder-component__text-editor" + (editorId === id ? " builder-component--active" : "")}*/
    />
  );
}
