import MetadataComponent from "../MetadataComponent";
import usePageLanguage from "../hooks/usePageLanguage";

export default function ProductMetadata({ locationProduct }: any) {
  const locale = usePageLanguage();


  const product_seo = locationProduct?.translations?.[locale].page_seo;
  if (!product_seo) {
    return <></>;
  }
  return <MetadataComponent page_seo={product_seo} />;
}
