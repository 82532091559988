import { memo } from 'react'
import ContentRenderWebpageComponent from './ContentRenderWebpageComponent'
import './Default.css'

interface Props {
    childWebpageComponentRelations: any
}

function RenderChildren({ childWebpageComponentRelations }: Props) {
    if (childWebpageComponentRelations && childWebpageComponentRelations.length) {
        return childWebpageComponentRelations.map((webpageComponentRelation: any) => {
            return <ContentRenderWebpageComponent componentId={webpageComponentRelation.child_id} key={webpageComponentRelation.child_id +"_"+ webpageComponentRelation.id} />
        })
    }


}

export default memo(RenderChildren)
