import { useState } from "react";
import { PaymentMethod, useShoppingCart } from "../context/ShoppingCartContext";
import CheckoutMaksekeskusModal from "./CheckoutMaksekeskusModal";
import usePageLanguage from "../hooks/usePageLanguage";

interface Props {
  paymentMethod: PaymentMethod;
  setShowParentModal?: (val: boolean) => void;
}

export default function CheckoutPaymentMethodButton({
  paymentMethod,
  setShowParentModal = () => {},
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const lang = usePageLanguage();
  const { setOrderPaymentMethod } = useShoppingCart();

  var maksekeskusPaymentMethod: any = null;
  if ((paymentMethod as any).maksekeskusPaymentMethod) {
    maksekeskusPaymentMethod = (
      paymentMethod as any
    ).api_client.maksekeskus_payment_methods.find(
      (i: any) => i.id == (paymentMethod as any).maksekeskusPaymentMethod
    );
  }

  if (paymentMethod.getMaksekeskusApiActive) {
    return (
      <>
        <button
          className="btn btn-primary-outline paymentMethodMaksekeskus shop-controlled-border-radius "
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          {paymentMethod.locale_translations[lang]?.name}
          {maksekeskusPaymentMethod ? (
            <img
              src={maksekeskusPaymentMethod.logo_url}
              style={{ float: "right", maxWidth: "65px" }}
            />
          ) : (
            ""
          )}
        </button>
        {showModal && (
          <CheckoutMaksekeskusModal
            setShowParentModal={setShowParentModal}
            paymentMethod={paymentMethod}
            show={showModal}
            setShow={setShowModal}
          />
        )}
      </>
    );
  }
  return (
    <button
      className="btn btn-primary-outline paymentMethodButton shop-controlled-border-radius"
      onClick={(e) => {
        e.preventDefault();
        setShowParentModal(false);
        setOrderPaymentMethod(paymentMethod);
      }}
    >
      {paymentMethod.image ? (
        <img src={paymentMethod.imageUrl} height="25px" />
      ) : (
        <span>{paymentMethod.locale_translations[lang]?.name}</span>
      )}
    </button>
  );
}
