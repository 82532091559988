import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import { format } from "date-fns";
import etLocale from "date-fns/locale/et";
import enLocale from "date-fns/locale/en-GB";
import CheckoutDeliveryMethodButton from "./CheckoutDeliveryMethodButton";
import WestIcon from "@mui/icons-material/West";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PersonIcon from "@mui/icons-material/Person";
import usePageLanguage from "../hooks/usePageLanguage";

interface Props {
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void;
  setShowDeliveryModal: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    delivery: "TARNE",
    back_to_shipping: "Tagasi tarneviisi valikusse",
    edit: "Muuda",
  },
  en: {
    delivery: "DELIVERY",
    back_to_shipping: "Back to shipping method selection",
    edit: "Edit",
  },
};

export default function CheckoutDeliveryPanel({
  setModalDeliveryMethod,
  setShowDeliveryModal,
}: Props) {
  const {
    order,
    deliveryMethods,
    orderDeliveryMethod,
    setOrderDeliveryMethod,
    buyer,
  } = useShoppingCart();

  const locale = usePageLanguage();

  var deliveryMethod: DeliveryMethod | null = null;
  if (orderDeliveryMethod) {
    deliveryMethod = deliveryMethods.find(
      (i) => i.id === orderDeliveryMethod.delivery_method_id
    )!;
  }

  var receiverEmail = "";
  var receiverFirstname = "";
  var receiverSurname = "";
  var receiverPhone = "";
  if (orderDeliveryMethod && orderDeliveryMethod.receiverEmail) {
    receiverEmail = orderDeliveryMethod.receiverEmail;
    receiverFirstname = orderDeliveryMethod.receiverFirstname;
    receiverSurname = orderDeliveryMethod.receiverSurname;
    receiverPhone = orderDeliveryMethod.receiverPhone;
  } else {
    receiverEmail = buyer.email;
    receiverFirstname = buyer.firstname;
    receiverSurname = buyer.surname;
    receiverPhone = buyer.phone;
  }

  var address = "";
  var city = "";
  var postal_code = "";
  var parcelMachineName = "";
  if (orderDeliveryMethod) {
    address = orderDeliveryMethod.address ?? "";
    city = orderDeliveryMethod.city ?? "";
    postal_code = orderDeliveryMethod.postal_code ?? "";
    parcelMachineName = orderDeliveryMethod.parcelMachineName ?? "";
  }

  return (
    <div className="panel" id="checkoutDeliveryPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary">3</span>
          <span className="panel-title-text">
            {languageTranslations[locale].delivery}
          </span>
          {order && order.delivery_time != undefined && (
            <span className="pull-right badge badge-square badge-primary delivery-date-badge">
              <span style={{ textTransform: "capitalize" }}>
                {locale === "et"
                  ? format(new Date(order.delivery_time), "EEEE", {
                      locale: etLocale,
                    })
                  : locale === "en"
                  ? format(new Date(order.delivery_time), "EEEE", {
                      locale: enLocale,
                    })
                  : locale === "ru"
                  ? "Russian Translation"
                  : "Default Translation"}
              </span>{" "}
              {format(new Date(order.delivery_time), "dd.MM.yyyy")}
            </span>
          )}
        </div>
      </div>
      <div className="panel-body">
        {!orderDeliveryMethod || !deliveryMethod ? (
          <div className="row deliveryMethods">
            {deliveryMethods
              ? deliveryMethods.map((dm) => (
                  <CheckoutDeliveryMethodButton
                    key={dm.id}
                    deliveryMethod={dm}
                    setShowDeliveryModal={setShowDeliveryModal}
                    setModalDeliveryMethod={setModalDeliveryMethod}
                  />
                ))
              : ""}
          </div>
        ) : (
          <div className="row" id="deliveryMethodFilled">
            <div className="col-xs-12">
              <button
                className="btn btn-pure backToDeliveryMethodSelect"
                onClick={() => setOrderDeliveryMethod(null)}
              >
                <WestIcon className="icon" style={{ fontSize: "14px" }} />{" "}
                <span>{languageTranslations[locale].back_to_shipping}</span>
              </button>
            </div>
            <CheckoutDeliveryMethodButton
              key={deliveryMethod.id}
              deliveryMethod={deliveryMethod}
              setShowDeliveryModal={setShowDeliveryModal}
              setModalDeliveryMethod={setModalDeliveryMethod}
            />

            <div className="col-xs-12">
              {orderDeliveryMethod.delivery_method_type !== "pickup" && (
                <h3 className="addressInfo margin-top-15 margin-bottom-0">
                  <PinDropIcon className="icon margin-right-10" />
                  <span>
                    <span className="receiverAddress">
                      {parcelMachineName ? parcelMachineName + " (" : ""}
                      {address}
                      {city.length ? ", " + city : ""}
                      {postal_code.length ? ", " + postal_code : ""}
                      {parcelMachineName ? ")" : ""}
                    </span>
                    <span
                      className="clickable editAddressButton"
                      onClick={() => {
                        setModalDeliveryMethod(deliveryMethod);
                        setShowDeliveryModal(true);
                      }}
                      style={{
                        color: "var(--shop-theme-color)",
                        fontWeight: "400",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {languageTranslations[locale].edit}
                    </span>
                  </span>
                </h3>
              )}
              {orderDeliveryMethod.receiverEmail &&
                orderDeliveryMethod.receiverEmail.length != 0 && (
                  <h3 className="receiverInfo margin-top-10 margin-bottom-0 ">
                    <PersonIcon className="icon" />{" "}
                    <span className="receiverName">
                      {receiverFirstname} {receiverSurname}
                    </span>
                    ,{" "}
                    <span
                      className="receiverPhone"
                      style={{ fontSize: "16px", fontWeight: "300" }}
                    >
                      {receiverPhone}
                    </span>{" "}
                    <span
                      className="receiverEmail"
                      style={{ fontSize: "16px" }}
                    >
                      {receiverEmail}
                    </span>
                  </h3>
                )}
            </div>
            {orderDeliveryMethod &&
            (orderDeliveryMethod.delivery_method_description ||
              orderDeliveryMethod.parcelDescription) ? (
              <div className="col-xs-12 deliveryDescription margin-top-15">
                {orderDeliveryMethod.parcelDescription ? (
                  <>{orderDeliveryMethod.parcelDescription}</>
                ) : (
                  ""
                )}
                {orderDeliveryMethod.delivery_method_description}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}
