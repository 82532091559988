import "./ProductsServicesBuyComponent.css";
import { useShoppingCart } from "../context/ShoppingCartContext";
import ProductServicesCartItem from "./ProductServicesCartItem";

export default function ProductServicesBuyComponent() {
  const { cartItems } = useShoppingCart();

  return (
    <div className="product-services">
      <span className="product-services-heading">Ostukorv</span>

      {cartItems.map((item) => {
        return <ProductServicesCartItem key={item.id} {...item} />;
      })}
    </div>
  );
}
