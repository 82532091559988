import { useShoppingCart } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";

export default function CheckoutPaymentPanelConfirmed({}) {
  const { orderPaymentMethod } = useShoppingCart();
  const lang = usePageLanguage();

  var maksekeskusPaymentMethod: any = null;
  if ((orderPaymentMethod as any)?.maksekeskusPaymentMethod) {
    maksekeskusPaymentMethod = (
      orderPaymentMethod as any
    ).api_client.maksekeskus_payment_methods.find(
      (i: any) => i.id == (orderPaymentMethod as any).maksekeskusPaymentMethod
    );
  }

  return (
    <div className="panel" id="checkoutPaymentmethodPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">4</span>
          <span className="panel-title-text">MAKSE</span>
        </div>
      </div>
      <div className="panel-body">
        <div className="row paymentMethodSelected">
          <div className="col-xs-12 selectedPaymentMethodButtonConainer">
            {orderPaymentMethod?.getMaksekeskusApiActive ? (
              <button className="btn btn-primary-outline paymentMethodMaksekeskus">
                {orderPaymentMethod.locale_translations[lang]?.name}
                {maksekeskusPaymentMethod ? (
                  <img
                    src={maksekeskusPaymentMethod.logo_url}
                    style={{
                      float: "right",
                      maxWidth: "65px",
                      marginBottom: "-5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </button>
            ) : (
              <button className="btn btn-primary-outline paymentMethodButton">
                {orderPaymentMethod?.image ? (
                  <img src={orderPaymentMethod.imageUrl} height="25px" />
                ) : (
                  <span>
                    {orderPaymentMethod?.locale_translations
                      ? orderPaymentMethod?.locale_translations[lang]?.name
                      : ""}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
