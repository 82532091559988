import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage325Avaleht from "./pages/Webpage325Avaleht";
import Webpage326Tingimused from "./pages/Webpage326Tingimused";
import Webpage327Terms from "./pages/Webpage327Terms";
import Webpage328K__psised from "./pages/Webpage328K__psised";
import Webpage329Tooted from "./pages/Webpage329Tooted";
import Webpage330Toode from "./pages/Webpage330Toode";
import Webpage331Tellimus from "./pages/Webpage331Tellimus";
import Webpage332Products from "./pages/Webpage332Products";
import Webpage333Product from "./pages/Webpage333Product";
import Webpage334Checkout from "./pages/Webpage334Checkout";
import Webpage335Homepage from "./pages/Webpage335Homepage";
import Webpage336Cookie_policy from "./pages/Webpage336Cookie_policy";
import Webpage337Not_found from "./pages/Webpage337Not_found";
import Webpage379templates from "./pages/Webpage379templates";

var baseURL = 'https://d2.itprojektid.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiZGExOTQyZjM3NTg5NDFlODZmMDgxNzE3NzJiMGNlOTg4NDBjZjNlNDE3NTUwMDRhN2M3Njc4OTE0MDhjMDhkYTA3MzQ2Yjc3OTY3NjBlMDAiLCJpYXQiOjE3MTc0ODA4MzIuNjAxNzUxLCJuYmYiOjE3MTc0ODA4MzIuNjAxNzU2LCJleHAiOjE3MTc1NjcyMzIuNTk3MjUzLCJzdWIiOiI3MSIsInNjb3BlcyI6W119.d1_PmKquw0780S6VWI9ekcthAycsg-tdfp4Ym2sg-esx4PTXZoWt0ZoPqmgTWxKRDjl_ARmfd-C8FJF8y6N81iT4gvRzBdBZnD_4B2CCjPsXiCb6jC3fK8MWykXlyldIqMucJwat8qG0rZZnXf97wzN4WOJFSmQV0SUuJVmpvykNfkW_l46dGEq52P1tyQxeNQLmrsGatpmvYYijIqbte-x4WTz3_3jH8vQ82m99DSC_RotDN3oRHzTCzKvQ3VLaCY_BQSMP_nTY_U-s9Al3ZLrj8ldiLAPBoFQXD93Z2J4PQ2f9G0Rq2uXxls2dUAPfRdSs9RBcnMdikuQW9ZXBOGMpe2Cwt5VKannut-HkWhhCCqZ9pCXAT2cc_SbHFaQZ4uKSjNvocBXD4sBZWiShLm3yJqVB8uuMhdak6ux2LTgZ5YhOUp5B0ZGZ2cZoRv1jWCShSg1I5BxVw_nZpTXlQ5oct8B6MSRBNtnSWFDMJATp5DvZcmvGgJdt5coZIPV2LzgaJY7WpY2mLe35PNS4PzxuYb9XGx55g-oBe4dybjiiKODePI4Ys8nO8CuDVlHBXciyNkCJQLchGJu4PHGRm8nrCQ1RrLGLqsz-8Xm43rOO9Uo54AYNpApQx-WD3xyYocU3Bl9pfVyY0EKStCCg4s9llSY1TSPCg_Cmdfz4RLk',
    'Access-api-key': "eyJpdiI6InhHQ3V1bzBhTVhaNi9Sc1JXOHlsZGc9PSIsInZhbHVlIjoiZ242aGhmUkxwVEJ1ZkFtSnlDdVlVUT09IiwibWFjIjoiMTc2MjRiYWJlYzFkYmUxZWYxZTQwNTljZDU0ZTZiNDZjNGExOTQ5MjFkOTk1ZTBjZmZmMDg2ZWI2YjFhNmYyNCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IjZGTUFCNys5bDF4dHQ4M0dLVG16NEE9PSIsInZhbHVlIjoidG02aWpzQThRcHdRdWNnRHQ0cm5EQT09IiwibWFjIjoiMzI3MjI0YWNiM2YyNjYxNTI4ZWE5N2VkZjA3YTQyMDFjOGE4ZjdjMjY5YTJmZjIwMDIyNzk1NjkwYjA1ODFiNyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "G-FQF99VNEVF"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage325Avaleht />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage326Tingimused />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage327Terms />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage328K__psised />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage329Tooted />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage330Toode />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage331Tellimus />} exact />;
                                                                    <Route path="/en/products" element={<Webpage332Products />} exact />;
                                                                    <Route path="/en/product/:id" element={<Webpage333Product />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage334Checkout />} exact />;
                                                                    <Route path="/en/" element={<Webpage335Homepage />} exact />;
                                                                    <Route path="/en/cookiepolicy" element={<Webpage336Cookie_policy />} exact />;
                                                                    <Route path="/et/*" element={<Webpage337Not_found />} exact />;
                                                    <Route path="*" element={<Webpage337Not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage379templates />} exact />;
                                                                                    </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}