import { useAppSelector } from "../../store/hooks";
import "./Logo.css";
import {
  AllComponents,
  LogoComponent as LogoComponent_,
} from "./interfaces/base";
import RenderChildren from "./RenderChildren";

import { useNavigate } from "react-router-dom";
import { getPageLocaleUrl } from "./utilities/helpers";

interface Props {
  id: number;
  editor: JSX.Element;
  type: "text" | "logo" | "none";
  attributes: LogoComponent_["attributes"];
  childElements: AllComponents[];
  childWebpageComponentRelations: [];
}

export default function LogoComponent({
  id,
  attributes,
  type,
  childElements,
  childWebpageComponentRelations,
}: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const isBuilder = document.getElementById("BuilderContent");
  const pages = useAppSelector((state) => state.builder.pages);

  let navigate = useNavigate();
  let href;

  if (attributes.link && attributes.link.pageId) {
    const page = pages.list.find((x) => x.id === attributes.link?.pageId);
    if (page) {
      href = getPageLocaleUrl(page);
    }
  } else if (
    attributes.link?.type === "Page" &&
    !attributes.link?.pageId &&
    attributes.link?.url
  ) {
    const page = pages.list.find((x) => x.url === attributes.link?.url);

    if (page) {
      href = getPageLocaleUrl(page);
    }
  } else if (
    attributes.link &&
    !attributes.link.pageId &&
    attributes.link.url
  ) {
    href = attributes.link.url;
  } else {
    href = `/`;
  }

  const routeChange = () => {
    if (attributes.link?.type === "Web") {
      const webHref = attributes.link?.url;
      window.location.href = webHref;
      return;
    } else if (attributes.link?.type === "Web_out") {
      if (!attributes.link.url.includes("http")) {
        const webHref = `https://${attributes.link?.url}`;
        window.location.href = webHref;
        return;
      } else {
        const webHref = attributes.link.url;
        window.location.href = webHref;
        return;
      }
    }

    if (attributes.link && attributes.link.pageId) {
      const page = pages.list.find((x) => x.id === attributes.link?.pageId);

      if (page) {
        href = getPageLocaleUrl(page);

        navigate(href);
        return;
      }
    } else if (
      attributes.link?.type === "Page" &&
      !attributes.link?.pageId &&
      attributes.link?.url
    ) {
      const page = pages.list.find((x) => x.url === attributes.link?.url);

      if (page) {
        href = getPageLocaleUrl(page);
      }
    } else if (!attributes.link.pageId && attributes.link.url) {
      let path = attributes.link.url;
      navigate(path);
      return;
    } else {
      let path = `/`;
      navigate(path);
      return;
    }

    // if (attributes.link!) {
    //   let path = attributes.link.url;
    //   navigate(path);
    //   return;
    // }

    // let path = `/`;
    // navigate(path);
  };

  return (
    type !== "none" && (
      <div
        id={id.toString()}
        data-component-type="logo"
        style={{ ...attributes.styles }}
        className={
          "navbar-brand " +
          (attributes.classes ?? "") +
          " builder-component builder-component__logo" +
          (editorId === id ? " builder-component--active" : "")
        }
        onClick={() => {
          if (isBuilder) {
            return;
          }
          routeChange();
        }}
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations}
        />
        {type == "text" && (
          <span contentEditable="true">
            {attributes.text ?? "text placeholder"}
          </span>
        )}
        {type == "logo" && (
          <>
            <div
              className="desktop-logo"
              style={{
                ...attributes.logo.Desktop.styles,
                height:
                  (
                    attributes.logo.Desktop.styles?.backgroundSize as string
                  )?.split(" ")[1] ?? "50px",
                width:
                  (
                    attributes.logo.Desktop.styles?.backgroundSize as string
                  )?.split(" ")[0] ?? "50px",
                cursor: "pointer",

                backgroundRepeat: "no-repeat",
              }}
              // Go to / url
              onClick={() => {
                routeChange();
              }}
            ></div>
            <div
              className="mobile-logo"
              style={{
                ...attributes.logo.Mobile.styles,
                height:
                  (
                    attributes.logo.Mobile.styles?.backgroundSize as string
                  )?.split(" ")[1] ?? "50px",
                width:
                  (
                    attributes.logo.Mobile.styles?.backgroundSize as string
                  )?.split(" ")[0] ?? "50px",
                cursor: "pointer",

                backgroundRepeat: "no-repeat",
              }}
              onClick={() => {
                routeChange();
              }}
            ></div>
          </>
        )}
      </div>
    )
  );
}
