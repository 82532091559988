import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { PaymentMethod, useShoppingCart } from "../context/ShoppingCartContext";
import { useAppSelector } from "../../../store/hooks";
import usePageLanguage from "../hooks/usePageLanguage";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
  paymentMethod: PaymentMethod | null;
  setShowParentModal?: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    payment_method: "MAKSEVIIS",
    payment_channel:
      "Kliki selle maksekanali logo peale, mida soovid kasutada.",
    save_data: "Andmete salvestamiseks tuleb nõustuda",
    data_usage: "  kasutustingimustega",
    cookie_privacy: " küpsiste- ja privaatsuspoliitikaga",
    agree_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
  },
  en: {
    payment_method: "PAYMENT METHOD",
    payment_channel:
      "Click on the logo of the payment channel you want to use.",
    save_data: "To save your data, you have to accept with",
    data_usage: " usage of data",
    cookie_privacy: " cookies and privacy policy",
    agree_terms: "AGREE WITH TERMS AND SAVE",
  },
};

export default function CheckoutMaksekeskusModal({
  show,
  setShow,
  paymentMethod,
  setShowParentModal,
}: Props) {
  const { setOrderPaymentMethod } = useShoppingCart();
  const pageId = useAppSelector((state) => state.builder.pages.active);
  const locale = usePageLanguage();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data: any) => {
    setShowParentModal!(false);
    setOrderPaymentMethod({ ...paymentMethod, ...data });
    setShow(false);
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      contentClassName="modal-600 paymentMethodModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="badge badge-square badge-primary me-3">4</span>
          <span className="fw-bold">
            {languageTranslations[locale].payment_method}
          </span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <Modal.Body>
          <div className="row margin-top-5 padding-10">
            <button className="btn btn-primary-outline col-xs-12 text-left paymentMethodButtonTitle">
              <span className="mk-payment-title">{paymentMethod?.name}</span>
            </button>
          </div>

          <div className="row margin-top-10">
            <div className="col-xs-12" style={{ fontSize: "15px" }}>
              {languageTranslations[locale].payment_channel}
            </div>
          </div>

          <div className="row margin-top-20">
            <div className="col-xs-12">
              <div className="mk-payment-options">
                {paymentMethod?.api_client &&
                paymentMethod.api_client.maksekeskus_payment_methods
                  ? paymentMethod.api_client.maksekeskus_payment_methods.map(
                      (mpm) => (
                        <label
                          key={mpm.id}
                          className="mk-payment-options__option"
                        >
                          <input
                            {...register("maksekeskusPaymentMethod", {
                              required: true,
                            })}
                            type="radio"
                            value={mpm.id}
                            style={{ display: "none" }}
                          />
                          <img src={mpm.logo_url} />
                        </label>
                      )
                    )
                  : ""}
                {errors.maksekeskusPaymentMethod && <p>This is required</p>}
              </div>
            </div>
          </div>

          <div className="mt-2 links-green">
            {languageTranslations[locale].save_data}
            <a href="/terms" target="_blank" className="color-primary">
              {languageTranslations[locale].data_usage}
            </a>
            ,{" "}
            <a href="/privacy" target="_blank" className="color-primary">
              {languageTranslations[locale].cookie_privacy}
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <button
            type="submit"
            style={{
              color: "#fff",
              fontWeight: "600",
            }}
            className="btn w-100 shop-controlled-border-radius shop-controlled-color"
          >
            {languageTranslations[locale].agree_terms}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
