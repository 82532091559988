import Modal from "react-bootstrap/Modal"
import { useShoppingCart } from "../context/ShoppingCartContext"
import CheckoutPaymentMethodButton from "./CheckoutPaymentMethodButton"

interface Props {
  show: boolean
  setShow: (val: boolean) => void
}

export default function CheckoutSelectPaymentMethodModal({ show, setShow }: Props) {
  const { paymentMethods } = useShoppingCart()

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="modal-600 selectPaymentMethodModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="badge badge-square badge-primary me-3 shop-controlled-color">
            4
          </span>
          <span className="fw-bold">VALI MAKSEVIIS</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="paymentMethodNotSelected padding-bottom-15">
          {paymentMethods
            ? paymentMethods.map((pm) => (
                <CheckoutPaymentMethodButton
                  key={pm.id}
                  paymentMethod={pm}
                  setShowParentModal={setShow}
                />
              ))
            : ""}
        </div>
      </Modal.Body>
    </Modal>
  );
}
