import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ButtonComponent as ButtonComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import RenderChildren from "./RenderChildren";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import { getPageLocaleUrl } from "./utilities/helpers";
import MegaMenuComponent from "./MegaMenuComponent";
import { EditorContent } from "@tiptap/react";
const Button = styled("button")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function ButtonComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: ButtonComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.id);
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [hoverStyle, setHoverStyle] = useState<any>();
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const isBuilder = document.getElementById("BuilderContent");
  let editor = useAppSelector((state) => state.builder?.editor?.editor);
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const component = useAppSelector(
    (state) => state.builder.structure.components
  ) as any;

  const [backgroundImage, setBackgroundImage] = useState(
    attributes.styles.backgroundImage ? attributes.styles.backgroundImage : ""
  );

  const buttonRefElement = useRef<HTMLButtonElement>(null);
  const loaded = useLazyLoad(buttonRefElement, backgroundImage);

  const pages = useAppSelector((state) => state.builder.pages);

  useEffect(() => {
    if (attributes.styles.backgroundImage) {
      setBackgroundImage(attributes.styles.backgroundImage);
    }
  }, [attributes.styles.backgroundImage]);

  const handleMegaMenuOpen = (boolean) => {
    if (attributes.link?.type === "MegaMenu") {
      setMegaMenuOpen(boolean);
    }
  };

  const buttonComponent = component[id] as ButtonComponent_;

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );
    if (compTypography) {
      setStyle({
        backgroundColor: attributes.styles.backgroundColor ?? "#fff",
        width: attributes.styles.width ?? "auto",

        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: attributes.styles.lineHeight ?? compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
        transition:
          compTypography.transition_duration +
          " color " +
          compTypography.transition_effect,
      });
      setHoverStyle({
        "&:hover": {
          color:
            colors?.find(
              (x: any) => x.id === compTypography.link_hover_color_id
            )?.color + " !important",
        },
      });
    }
  }, [typographies, attributes]);

  // If builder, do not render submit button, or else cant edit the button while in a form

  let href;

  if (attributes.link?.type === "Page" && attributes.link?.pageId) {
    const page = pages.list.find((x) => x.id === attributes.link?.pageId);

    if (page) {
      href = getPageLocaleUrl(page);
    } else if (attributes.link.url) {
      href = getPageLocaleUrl(page) + attributes.link.url;
    }
  } else if (
    attributes.link?.type === "Page" &&
    !attributes.link?.pageId &&
    attributes.link?.url
  ) {
    const page = pages.list.find((x) => x.url === attributes.link?.url);

    if (page) {
      href = getPageLocaleUrl(page);
    }
  } else if (attributes.link?.type === "Web_out") {
    if (!attributes.link.url.includes("http")) {
      href = `https://${attributes.link?.url}`;
    } else {
      href = attributes.link.url;
    }
  } else if (attributes.link?.type === "Web") {
    if (attributes.link?.url.startsWith("/")) {
      href = attributes.link?.url;
    } else {
      href = "/" + attributes.link?.url;
    }
  } else if (attributes.link?.type === "None") {
    href = "";
  } else if (attributes.link?.type === "Anchor") {
    const anchorLink = attributes.link.url.startsWith("#")
      ? attributes.link.url
      : `#${attributes.link.url}`;

    href = anchorLink;
  } else if (attributes.link?.type === "Phone") {
    if (attributes.link.url.startsWith("tel:")) {
      href = attributes.link.url;
    } else {
      href = `tel:${attributes.link.url}`;
    }
  } else if (attributes.link?.type === "Email") {
    if (attributes.link.url.startsWith("mailto:")) {
      href = attributes.link.url;
    } else {
      href = `mailto:${attributes.link.url}`;
    }
  }

  function generateLink(link: any) {
    let href;

    console.log(link, "THIS IS THE LINK");

    if (link.type === "Page" && link.url) {
      const page = pages.list.find((x) => x.url === link.url);

      console.log(link, "THIS IS THE LINK");

      if (page) {
        href = getPageLocaleUrl(page);
      }
    } else if (
      attributes.link?.type === "Page" &&
      !attributes.link?.pageId &&
      attributes.link?.url
    ) {
      const page = pages.list.find((x) => x.url === attributes.link?.url);

      if (page) {
        href = getPageLocaleUrl(page);
      }
    } else if (link?.type === "Web_out") {
      if (!link.url.includes("http")) {
        href = `https://${link.url}`;
      } else {
        href = link.url;
      }
    } else if (link?.type === "Web") {
      if (link?.url.startsWith("/")) {
        href = link?.url;
      } else {
        href = "/" + link?.url;
      }
    } else if (link?.type === "None") {
      href = "";
    } else if (link?.type === "Anchor") {
      const anchorLink = link.url.startsWith("#") ? link.url : `#${link.url}`;

      href = anchorLink;
    } else if (link.type === "Phone") {
      if (link.url.startsWith("tel:")) {
        href = link.url;
      } else {
        href = `tel:${link.url}`;
      }
    } else if (link?.type === "Email") {
      if (link.url.startsWith("mailto:")) {
        href = link.url;
      } else {
        href = `mailto:${link.url}`;
      }
    }

    return link.type === "Page" ? (
      <Link
        className="button-link"
        key={link.text + link.type + href}
        to={`${href}`} // Avoid redirecting when editing
        target={link.type !== "None" ? link.target : "sefsefes"}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
      ></Link>
    ) : (
      <a
        className="button-link"
        key={link.text + link.type + href}
        href={href}
        target={
          link.type !== "None" && link.type !== "" ? link.target : "_self"
        }
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
        onClick={() => {}}
        style={{
          zIndex: 8,
        }}
      ></a>
    );
  }

  useEffect(() => {
    const backgroundColor = buttonComponent.attributes?.color_id
      ? colors?.find((x: any) => x.id === buttonComponent.attributes?.color_id)
          ?.color
      : buttonComponent.attributes?.styles.backgroundColor
      ? buttonComponent.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = buttonComponent.attributes?.hover_color_id
      ? colors?.find(
          (x: any) => x.id === buttonComponent.attributes?.hover_color_id
        )?.color
      : buttonComponent.attributes?.styles[":hover"] &&
        buttonComponent.attributes?.styles[":hover"].backgroundColor
      ? buttonComponent.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "#fff"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    buttonComponent.attributes,
    buttonComponent.attributes?.color_id,
    buttonComponent.attributes?.hover_color_id,
    buttonComponent.attributes?.styles.backgroundColor,
    buttonComponent.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  return (
    <Button
      ref={buttonRefElement}
      as={
        attributes?.buttonType === "submit"
          ? "button"
          : attributes.link?.type === "Web" ||
            attributes.link?.type === "Web_out" ||
            attributes.link?.type === "Anchor"
          ? "a"
          : attributes.link?.type === "Page"
          ? Link
          : null
      }
      to={href}
      style={
        attributes.link?.type === "Web" ||
        attributes.link?.type === "Page" ||
        attributes.link?.type === "Web_out" ||
        attributes.link?.type === "Anchor"
          ? {
              textDecoration: "none",
              display: component[id].attributes.styles.display
                ? component[id].attributes.styles.display
                : "inline-grid",

              alignItems: "center",
              justifyContent: buttonComponent.attributes.textAlign
                ? buttonComponent.attributes.textAlign
                : "center",
            }
          : null
      }
      target={
        attributes.link?.type === "Web" ||
        attributes.link?.type === "None" ||
        attributes.link?.type === "Anchor" ||
        attributes.link?.type === "Phone" ||
        attributes.link?.type === "Email"
          ? "_self"
          : attributes.link?.target
      }
      href={href}
      // href={href}
      id={id.toString()}
      type={attributes.buttonType ? attributes.buttonType : "button"}
      className={
        "builder-component relative builder-component__button " +
        (attributes.classes ?? "") +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={{
        ...style,
        ...attributes.styles,
        ...hoverStyle,

        backgroundImage: !isBuilder
          ? loaded
            ? loaded.map((src) => `url("${src}")`).join(", ")
            : "none"
          : attributes.styles.backgroundImage,

        // Set the border widths for each border side, left right, top and bottom

        borderLeftWidth: attributes.styles.borderLeftWidth
          ? attributes.styles.borderLeftWidth
          : "0px",
        borderRightWidth: attributes.styles.borderRightWidth
          ? attributes.styles.borderRightWidth
          : "0px",
        borderTopWidth: attributes.styles.borderTopWidth
          ? attributes.styles.borderTopWidth
          : "0px",
        borderBottomWidth: attributes.styles.borderBottomWidth
          ? attributes.styles.borderBottomWidth
          : "0px",
        ...backgroundStyles,
        backgroundColor: backgroundStyles?.backgroundColor
          ? backgroundStyles?.backgroundColor
          : "none",
      }}
      data-component-type="button"
      onClick={(e) => {
        e.stopPropagation();

        if (isBuilder) {
          e.preventDefault();
        }

        handleMegaMenuOpen(!megaMenuOpen);
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        handleMegaMenuOpen(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();

        handleMegaMenuOpen(false);
      }}
      draggable={false}
    >
      {attributes &&
        attributes.link &&
        attributes.link.type === "MegaMenu" &&
        attributes.link.template_id !== null && (
          <>
            <MegaMenuComponent
              id={attributes.link.template_id}
              parentId={id}
              menuOpen={megaMenuOpen}
              setMegaMenuOpen={setMegaMenuOpen}
              leftPosition={attributes.link?.left_position}
              topPosition={attributes.link?.top_position}
              activateType={attributes.link?.activate_type}
            />
          </>
        )}

      {attributes &&
        attributes.link &&
        attributes.link.type === "Dropdown" &&
        attributes.links &&
        attributes.links.length > 0 && (
          <>
            <div className="button-subnav">
              {attributes.links && attributes.links.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {attributes.links.map((item, i) => generateLink(item))}
                </div>
              )}
            </div>
          </>
        )}

      <BackgroundVideoComponent id={id} />
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />

      {!isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          <div
            dangerouslySetInnerHTML={{ __html: attributes.text }}
            contentEditable={editorId == id}
            // onMouseUp={(e) => setMouseUp(e)}
            // onKeyDown={(e) => setKeyDown(e)}
            // onBlur={(e) => setOnBlur(e)}
          ></div>
        </>
      )}

      {editorId !== id && isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          <div
            style={{}}
            dangerouslySetInnerHTML={{ __html: attributes.text }}
            contentEditable={editorId == id}
            // onMouseUp={(e) => setMouseUp(e)}
            // onKeyDown={(e) => setKeyDown(e)}
            // onBlur={(e) => setOnBlur(e)}
          ></div>
        </>
      )}

      {isBuilder && editorId === id && editor && (
        <>
          <div className="builder-component_text-data">
            <EditorContent
              id={id + "_content"}
              content={editor.getHTML()}
              editor={editor}
            ></EditorContent>
          </div>
        </>
      )}
    </Button>
  );
}
